<template>
  <base-popup
      :popup-model="show"
      title="Generated links"
      :valid="!valid"
      :width="45"
      :show-save-button="false"
      @onCancelButtonClick="closeOrderDeliveryBodyPopupHandler"
  >
    <v-card class="mt-5">
      <v-card-text id="myInput" class="mail-text-weight" v-html="currentItem.mailBody">
      </v-card-text>
    </v-card>
  </base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";

export default {
  name: "OrderDeliveryMailBodyPopup",
  mixins: [Popup],
  components: {BasePopup},
  methods: {
    closeOrderDeliveryBodyPopupHandler() {
      this.$emit('closeOrderDeliveryBodyPopupHandler');
    },
  }
}
</script>

<style scoped>

</style>