<template >
  <div>
    <v-card class="ma-3">
      <g-overlay :is-relay="isOverlay"/>
      <page-progress v-bind:is-loading="isLoading"/>
      <page-title icon="mdi-truck-delivery" title="Order Delivery"/>
      <v-container class="pt-0" fluid>
        <v-form ref="cartFormRef">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-data-table
                      :headers="orderHeaders"
                      :items="getOrderData"
                      class="elevation-0 mt-0"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="12" sm="8">
                            <g-p-search
                                :loading="isLoading"
                                class="mt-7"
                                @applySearch="handleSearch"
                            />
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:no-data>
                      Order is empty!
                    </template>
                    <template v-slot:item.liveLinks={item}>
                      <v-chip
                          class="ma-2"
                          color="green"
                          link
                          text-color="white"
                          @click.prevent.stop="liveLinkHandler(item)"
                      >
                        View Live Link
                      </v-chip>
                    </template>
                    <template v-slot:item.amount={item}>
                      {{ getAmountWithSymbol(item.amount) }}
                    </template>
                    <template v-slot:item.publishableAmount={item}>
                      {{ getAmountWithSymbol(item.publishableAmount) }}
                    </template>
                    <template v-slot:item.discount={item}>
                      {{ getAmountWithSymbol(item.discount) }}
                    </template>
                    <template v-slot:item.totalAmount={item}>
                      {{ getAmountWithSymbol(item.totalAmount) }}
                    </template>
                    <template v-slot:item.paymentStatus="{ item }">
                      <g-payment-status-chip v-if="item.isDeleted && item.paymentStatus === 900" :text="getDeleteStatus(item.billingType,'Removed')" color="indigo"/>
                      <g-payment-status-chip v-else-if="item.isDeleted" :text="getDeleteStatus(item.billingType,'Deleted')" color="blue"/>
                      <g-payment-status-chip v-else-if="item.paymentStatus === 100"/>
                      <g-payment-status-chip v-else-if="item.paymentStatus === 200" color="green" text="Paid"/>
                      <g-payment-status-chip v-else-if="item.paymentStatus === 300" color="lime" text="Pending"/>
                      <g-payment-status-chip v-else-if="item.paymentStatus === 400" color="yellow" text="Refund"/>
                      <g-payment-status-chip v-else-if="item.paymentStatus === 600" color="orange" text="Republish"/>
                    </template>
                    <template v-slot:item.actions={item}>
                      <v-simple-checkbox
                          color="primary"
                          v-model="item.isInCart"
                          @click="addToCartHandler(item)"
                      />
                    </template>
                    <template v-slot:body.append>
                      <tr color="red">
                        <td colspan="7" style="text-align: right">
                          <h3>Select Sender Mail</h3>
                        </td>
                        <td style="text-align: right;padding-top: 20px;" colspan="2">
                          <v-select
                              v-model="mailSender"
                              :items="mailSenders"
                              :rules="requiredFieldRule"
                              item-text="name"
                              item-value="id"
                              class="discount-field mt-2 mb-1"
                              dense
                              outlined
                          />
                        </td>
                      </tr>
                    </template>
                    <template v-slot:footer>
                      <v-divider/>
                      <v-toolbar flat>
                        <v-spacer/>
                        <confirm-button
                            v-ripple
                            text="Generate"
                            :disabled="!carts.length > 0"
                            @onButtonClick="onGenerateHandler"
                        />
                        <confirm-button
                            v-ripple
                            text="Send"
                            :disabled="!carts.length > 0 || !mailSender"
                            @onButtonClick="onConfirmHandler"
                        />
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        </v-form>
      </v-container>
      <confirm-popup
          :show="showConfirm"
          confirm-event="onConfirmOrder"
          @closePopupHandler="showConfirm = false"
          @onConfirmOrder="onClickFunctionHandler(popupHandler)"
      >
        <div>
        <span>{{popupText}}</span>
        </div>
      </confirm-popup>
      <order-delivery-mail-body-popup :show="showOrderMailBody" :item="{mailBody}" @closeOrderDeliveryBodyPopupHandler="closeOrderDeliveryBodyPopupHandler"/>
    </v-card>
  </div>
</template>

<script>
import GOverlay from "../components/GOverlay";
import PageProgress from "../components/PageProgress";
import PageTitle from "../components/PageTitle";
import GPSearch from "../components/GPSearch";
import GPaymentStatusChip from "../components/GPaymentStatusChip";
import SalesCounter from "../services/SalesCounter";
import ConfirmButton from "../components/buttons/ConfirmButton";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import Validation from "../components/mixins/Validation";
import OrderDeliveryMailBodyPopup from "../components/popups/OrderDeliveryMailBodyPopup";
export default {
  name: "OrderDelivery",
  components: {
    OrderDeliveryMailBodyPopup,
    ConfirmPopup,
    ConfirmButton,
    GPaymentStatusChip,
    GPSearch,
    PageTitle,
    PageProgress,
    GOverlay
  },
  mixins: [Validation],
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Panel - Order Delivery | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  },
  data() {
    return {
      isOverlay: false,
      isLoading: false,
      showLiveLink: false,
      currentItem: null,
      showConfirm: false,
      showOrderMailBody: false,
      orderItems: [],
      carts: [],
      mailSenders: [
        {name: 'Support', id: 'support'},
        {name: 'Admin', id: 'admin'},
        {name: 'Info', id: 'info'},
        {name: 'Payment', id: 'payment'},
        {name: 'Contact', id: 'contact'},
        {name: 'Pbn', id: 'pbn'},
      ],
      orderHeaders: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Live Links',
          value: 'liveLinks'
        },
        {
          text: 'Payment Status',
          value: 'paymentStatus'
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'right'
        },
        {
          text: 'Publishable Amount',
          value: 'publishableAmount',
          align: 'right'
        },
        {
          text: 'Discount',
          value: 'discount',
          align: 'right'
        },
        {
          text: 'Total Amount',
          value: 'totalAmount',
          align: 'right'
        },
        {
          text: 'Actions', value: 'actions', sortable: false
        }
      ],
      mailSender: '',
      popupText: 'Are you sure to sending delivery mail of this selected orders?',
      popupHandler: 'onConfirmHandler',
      mailBody: null
    }
  },
  computed: {
    getOrderData() {
      return this.orderItems.map((item)=> {
        const isInCart = this.carts.find((data) => data.id === item.id)
        return {
          ...item,
          isInCart: !!isInCart
        }
      });
    },
  },
  methods: {
    onClickFunctionHandler(event) {
      return this[event](true);
    },
    handleSearch(term) {
      if(term) {
        SalesCounter
            .search({search: term})
            .then((response) => {
              this.orderItems = response.data.data;
              if(response.data.data.length === 0) {
                this.setSnackBar({text: "Unpaid order not found!", type: 'info'});
              }
            })
            .catch((errors) => {
              console.log(errors);
            })
      }
    },
    liveLinkHandler(item) {
      this.showLiveLink = true;
      this.currentItem = item;
    },
    getAmountWithSymbol(amount) {
      const _amount = Number.parseFloat(amount).toFixed(2);
      return `$${_amount}`;
    },
    addToCartHandler(item) {
      const {id, isInCart, orderId} = item;

      const index = this.carts.findIndex((data) => data.id === id);

      if(!isInCart) {
         this.carts.splice(index, 1);
      } else {
        let cart = {
          id,
          orderId,
          isInCart: true
        };

        this.carts.push(cart);
      }
    },
    setSnackBar({text, type}) {
      this.$store.commit('SET_SNACKBAR', {text, type});
    },
    getDeleteStatus(type, text) {
      if(!type){
        return text;
      } else if(type === "Link Insertion on" || type === "Home Page Link Insertion on"){
        return 'Link Insert '+text;
      } else {
        return text;
      }
    },
    async onGenerateHandler(confirmed = false) {
      if (!confirmed) {
        this.popupHandler = 'onGenerateHandler';
        this.popupText = 'Are you sure to generate delivery mail body of this selected orders?';
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;

      this.$store.dispatch('generateOrderDeliveryBody', {orders: this.carts, mailSender: this.mailSender})
          .then((response) => {
            this.mailBody = response.data.mailBody;
            this.showOrderMailBody = true;
          })
          .catch((error) => {
            this.setSnackBar({text: "Data Validation Error", type: 'error'});
          })
    },
    async onConfirmHandler(confirmed = false) {
      if (!this.$refs.cartFormRef.validate()) return;

      if (!confirmed) {
        this.popupHandler = 'onConfirmHandler';
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;

      this.$store.dispatch('sendOrderDeliveryEmail', {orders: this.carts, mailSender: this.mailSender})
          .then((response) => {
            this.setSnackBar({text: response.data.message, type: 'info'});
            this.carts = [];
            this.orderItems = [];
            this.mailSender = ''
          })
          .catch((error) => {
            this.setSnackBar({text: "Data Validation Error", type: 'error'});
          })
    },
    closeOrderDeliveryBodyPopupHandler() {
      this.popupHandler = 'onConfirmHandler';
      this.popupText = 'Are you sure to sending delivery mail of this selected orders?';
      this.carts = [];
      this.orderItems = [];
      this.mailSender = '';
      this.showOrderMailBody = false;
    }
  }
}
</script>

<style scoped>

</style>